import React, { useState, useEffect, useRef, Component } from "react";

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import ReactGA from "react-ga4";
import EventBus from "../common/EventBus";
import "../components/BoardUser.css";
import styles from '../components/BoardUser.css';
import Legend from "./Legend";

//geoRaster
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";

//import GeoTiffLayer from "../components/GeoRaster.js";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

import { MapContainer, TileLayer, GeoJSON, ImageOverlay, WMSTileLayer } from "react-leaflet";


import "leaflet/dist/leaflet.css";
import L from 'leaflet';

import bbox from "geojson-bbox"

import AddIcon from '@mui/icons-material/Add';

import { Button, Badge, Container, Card, CardGroup, ListGroup, Col, Row, Nav, Navbar, NavDropdown, Form, Image } from 'react-bootstrap';
import {
  Alert,
  Box,
  Chip,
  Stack,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  FormControlLabel,
  CircularProgress,
  Skeleton,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Fab,
  Snackbar,
  colors,
  Dialog,
  AppBar,
  Toolbar,
  List,
  ListItem,
  Switch,
  ListItemText,
  Divider,
  Slide,

  // getIconButtonUtilityClass
} from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { area, polygon, multiPolygon } from '@turf/turf'
import axios from 'axios';

import Swal from 'sweetalert2'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { SearchBar } from "../components/SearchBar";
import { SearchResultsList } from "../components/SearchResultsList";
import { SearchResult } from "../components/SearchResult";
import { red } from "@mui/material/colors";



let colors2 = ["fe4848", "fe6c58", "fe9068", "feb478", "fed686"];
let labels = ["2-12.5", "12.6-16.8", "16.9-20.9", "21-25.9", "26-plus"];



let style = { color: '#B5B0B0' };

let center = [13, 100];
function onEachFeature(feature, layer) {
  layer.bindPopup(feature.properties.des_)
}
let tileLayer = {
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}

let tileLayer_ESRI = {
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  maxZoom: 21,

}



let tileLayer_sphere = {
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://basemap.sphere.gistda.or.th/tiles/thailand_images/EPSG3857/{z}/{x}/{y}.jpeg?key=0BAF97C307C24B28A7E19CE1D9B1DE88',
  maxZoom: 21,
}







let sphere = null;
let map = null;
let displayKey = null;

let geomInsert
let area_polygon
let lat
let lng
let str
//calulate display
//var el3 = 5000
let url_draw = "https://drought-765rkyfg3q-as.a.run.app/api/insert_parcel_web";
let url_parcel_select = "https://drought-765rkyfg3q-as.a.run.app/api/select_parcel";
let url_analysis_parcel = "https://drought-765rkyfg3q-as.a.run.app/api/analysis_parcel";
let url_del_parcel = "https://drought-765rkyfg3q-as.a.run.app/api/delete_parcel";

let currentDate = new Date();
let year = new Date(currentDate.getFullYear(), 0, 1);
let days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
let week = Math.ceil((currentDate.getDay() - 1 + days) / 7);

console.log(week - 1)

let week_ = week - 1

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


let date_today = new Date();
console.log(`The current day index of the week is: ${date_today.getDay() - 1}`);

console.log(`The current date of the week is: ${date_today.getDate() - 7}`);

let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate() - 7
  - date_today.getDay() + 1));
let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
  - date_today.getDay() + 7));
console.log(`The first date of the week is: ${first_day_of_the_week}`);
console.log(`The last day of the week is: ${last_day_of_the_week}`);

var firstday = first_day_of_the_week.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
console.log(firstday)
var lastday = last_day_of_the_week.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
console.log(lastday)



const BoardUser = () => {


  const [results, setResults] = useState([]);

  const [content, setContent] = useState("");
  const [areaValue, setareaValue] = useState("0");
  const [fetchedData, setFetchedData] = useState([]);
  //const [geomInsert, setgeomInsert] = useState('');
  //const [area_polygon, setarea_polygon] = useState("");
  //const [str, setstr] = useState('');
  //const [message, setMessage] = useState();
  const [fetarea_polygon, setFetarea_polygon] = useState(0);
  const [fetgeomInsert, setFetgeomInsert] = useState(0);
  const [name, setName] = useState("");
  const [type, settype] = useState("");
  const [pat, setpat] = useState("web");;
  const [file, setfile] = useState("");
  const currentUser = AuthService.getCurrentUser();
  const [status, setStatus] = useState("");

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const [poi, setPoi] = useState("");


  const [response, setResponse] = useState({});
  const [errors, setErrors] = useState({});

  const [responseTable, setResponseTabel] = useState({});
  const [errorsTable, setErrorsTable] = useState({});

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);


  const [opencdai, setOpencdai] = React.useState(false);

  const handleClickOpencdai = () => {
    setOpencdai(true);
  };

  const handleClosecdai = () => {
    setOpencdai(false);
  };


  const [mean_cdai, setmean_cdai] = useState("");
  const [mean_dri, setmean_dri] = useState("");
  const [mean_ssm, setmean_ssm] = useState("");

  const [url_img, seturl_img] = useState("");


  const [geomdata_get, setgeomdata_get] = useState("");
  const [data_get, setdata_get] = useState("");

  const [geomextent, setgeomextent] = useState("");




  // const mapRef = useRef(null);
  // const latitude = 51.505;
  // const longitude = -0.09;

  const [maptest, setMap] = useState(null)


  //graph
  const [data_temp_min, setdata_temp_min] = useState("");
  const [data_temp_max, setdata_temp_max] = useState("");
  const [dataname, setdataname] = useState("");
  const [datanamepro, setdatanamepro] = useState("");
  const [data_rain, setdata_rain] = useState("");
  const [data_weatherTypeText, setdata_weatherTypeText] = useState("");
  const [data_windSpeed, setdata_windSpeed] = useState("");
  const [data_rh, setdata_rh] = useState("");
  const [data_weatherTypeImagePath, setdata_weatherTypeImagePath] = useState("");

  const [dri_f, setdri_f] = useState("");


  //layerbaseMap1
  const [checked0_1, setChecked0_1] = React.useState(false);
  const handleToggle0_1 = () => () => {
    if (!checked0_1) {
      let layerbaseMap1 = new sphere.Layer('', {
        type: sphere.LayerType.XYZ,
        url: "https://basemap.sphere.gistda.or.th/tiles/sphere_streets/EPSG3857/{z}/{x}/{y}.png?".replace("key=test2022", "key=test2022"),
        zoomRange: { min: 1, max: 21 },
        zIndex: 5,
        opacity: 0,
        id: 'layerbaseMap1'
      });
      map.Layers.add(layerbaseMap1);
      //document.getElementById("demo").innerHTML = "วันที่ของข้อมูล";   
    } else {
      let layerbaseMap1 = new sphere.Layer('', {
        type: sphere.LayerType.XYZ,
        url: "https://basemap.sphere.gistda.or.th/tiles/sphere_streets/EPSG3857/{z}/{x}/{y}.png?".replace("key=test2022", "key=test2022"),
        zoomRange: { min: 1, max: 21 },
        zIndex: 5,
        opacity: 0,
        id: 'layerbaseMap1'
      });
      map.Layers.remove(layerbaseMap1);

    }
    setChecked0_1(!checked0_1);
  };
  //layerprovince
  const [checked0, setChecked0] = React.useState(false);
  const handleToggle0 = () => () => {
    if (!checked0) {
      let layer_province = new sphere.Layer('', {
        type: sphere.LayerType.XYZ,
        url: "http://{s}.google.com/vt?lyrs=s,h&".replace("x={x}&y={y}&z={z}.png", "x={x}&y={y}&z={z}.png"),
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        //type: sphere.LayerType.WMS,
        // url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
        //zoomRange: { min: 1, max: 21 },
        //zIndex: 5,
        //opacity: 0,
        id: 'dsi_m2'
      });
      map.Layers.add(layer_province);
      //document.getElementById("demo").innerHTML = "วันที่ของข้อมูล";   
    } else {
      let layer_province = new sphere.Layer('', {
        type: sphere.LayerType.XYZ,
        url: "http://{s}.google.com/vt?lyrs=s,h&x={x}&y={y}&z={z}",
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        // type: sphere.LayerType.WMS,
        // url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
        zoomRange: { min: 1, max: 21 },
        zIndex: 5,
        opacity: 0,
        id: 'dsi_m2'
      });
      map.Layers.remove(layer_province);

    }
    setChecked0(!checked0);
  };







  //รายวัน
  const getChartData = async (obj) => {
    //setIsLoadingChart(true);
    //setTableData(tableData)



    const extent = bbox(obj.geom_get);
    console.log(extent)

    const lat = extent[1]
    const lon = extent[0]
    console.log(lat, lon)

    //const headers = { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjZlM2E2NjJlYmE0NDgyM2NmZWUxNzlmMDAxMGNhYjE0YWYxYzExZGFhNGZjOTllYTRkNTdhZjVhZjdhOTQxYzg5Yzc4ODI2ZTdjOWYxMWYxIn0.eyJhdWQiOiIyIiwianRpIjoiNmUzYTY2MmViYTQ0ODIzY2ZlZTE3OWYwMDEwY2FiMTRhZjFjMTFkYWE0ZmM5OWVhNGQ1N2FmNWFmN2E5NDFjODljNzg4MjZlN2M5ZjExZjEiLCJpYXQiOjE2OTkxNzAwMzksIm5iZiI6MTY5OTE3MDAzOSwiZXhwIjoxNzMwNzkyNDM5LCJzdWIiOiIyNzE5Iiwic2NvcGVzIjpbXX0.p3sOJu0-p06yGlhiMb0ozijA3BuafmcXrfMSXBkcT_6Nh0wFpWzm7V_9wl2e6S0eLtErmljC6gWMPHA6qVO5A4uyK8Sa6WN_bi6zcIL1zXfHnf9RWDhX9SNmlsjwJWm_ZxQesqAG0cfKB9pzN7ys7XH5ym1pww-c4md5DGmPJDDx4Ch-LZM_WfjqZ2foVy7YLKylxIntB7tL_-H2rV-h87-xoiSJCzr2XuvsGm0YroTmRpDh9cnS42tguQ5_Ab10irUQzL_1e7eEHKdGsNNbeKeVEUtMpr0q8WbuzZm8ysSSc-xWZc9bbxH2teMDaCiakbRs4oxWFgJCfHCGa1EDuCfkJC9OeDVFhDnt5zsOkOMYSu4L1XJvQ_hGGahOiuP4xqjywlNyA1H412_daYwtw-kSex4zJ-S3gO-y3dkL-2epH71NyRUp6WeZ9BosTBX_yZowWsc8_pwnhCG2H9xdZr3uH6y2eNnBk3RyVQWS734kPMwxeb0xcUvjqYrkjrUBVEHuKORd4rYBec2XQuPJYeTMlWcRt3BiDWI53Vn36g564Pj3MwwF5ki-zsp2vIxTjn1G3MwZXuvWS7subYKMwza0YjB0s3pFaJYNAvZXhjOiRHE6gWeLcmAqZy1s3k3R__tYPG3HHkHM1KESdcXNFAqeq-qpzDlHXH2TEzLpSeM' };
    // const response = await axios.get(
    //   `https://data.tmd.go.th/nwpapi/v1/forecast/location/daily/at?lat=${lat}&lon=${lon}&fields=tc_min,tc_max,rh,rain,ws10m,cond&duration=7`
    //   , { headers });

    const response = await axios.get(
      `https://drought-765rkyfg3q-as.a.run.app/rest/tmd?geocode=720114`
      ,);

    console.log(response.data.data);


    const result = response.data.data
    //setIsLoadingChart(false);


    var data_temp_min = [];
    var data_temp_max = [];
    var dataname = [];
    var datanamepro = [];
    var data_rain = [];
    var data_weatherTypeText = [];
    var data_windSpeed = [];
    var data_rh = [];
    var data_weatherTypeImagePath = [];

    for (var i = 0; i < result.WeatherForecasts[0].forecasts.length; i++) {
      data_temp_min.push(result.WeatherForecasts[0].forecasts[i].data.tc_min);
      data_temp_max.push(result.WeatherForecasts[0].forecasts[i].data.tc_max);
      data_rain.push(result.WeatherForecasts[0].forecasts[i].data.rain);
      data_windSpeed.push(result.WeatherForecasts[0].forecasts[i].data.ws10m);
      data_rh.push(result.WeatherForecasts[0].forecasts[i].data.rh);
      data_weatherTypeText.push(result.WeatherForecasts[0].forecasts[i].data.cond);
      datanamepro.push(result.WeatherForecasts[0].location.province);
      dataname.push(result.WeatherForecasts[0].forecasts[i].time.replace("T00:00:00+07:00", ""));
    }

    //console.log(data_temp_min)
    //console.log(data_temp_max)
    //console.log(dataname)
    // console.log(data_weatherTypeText)
    // console.log(datanamepro)

    setdata_temp_min(data_temp_min)
    setdata_temp_max(data_temp_max)
    setdata_rain(data_rain)
    setdata_weatherTypeText(data_weatherTypeText)
    setdata_windSpeed(data_windSpeed)
    setdataname(dataname)
    setdatanamepro(datanamepro)
    setdata_windSpeed(data_windSpeed)
    setdata_rh(data_rh)
    setdata_weatherTypeImagePath(data_weatherTypeImagePath)


    setIsLoading(false);


  }


  //กราฟอุณหภูมิรายวัน
  const options = {

    chart: {
      type: 'spline'
    },
    title: {
      text: 'คาดการณ์อุณหภูมิรายวัน' + ' '
    },
    subtitle: {
      text: 'ที่มาข้อมูล: ' +
        '<a href="https://www.tmd.go.th/" ' +
        'target="_blank">กรมอุตุนิยมวิทยา</a>'
    },
    xAxis: {
      //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      categories: dataname
    },
    yAxis: {
      title: {
        text: 'อุณหภูมิ (°C)'
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: false
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'อุณหภูมิตํ่าสุด',
      color: '#0066FF',
      data: data_temp_min
    }, {
      name: 'อุณหภูมิสูงสุด',
      color: '#DF603A',
      data: data_temp_max
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }


  };


  useEffect(() => {

    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/user", title: "BoardUser" });

    let urlProvince = `https://drought-765rkyfg3q-as.a.run.app/rest/zonal/dri/province`;
    getData(urlProvince, 'pv');
    setIsLoading(true);



    console.log(setResults(results))


    init()
    drawParcel()
    getData()
    tableParcel()

    //getChartData()

    //analysis()


    // maptest.addControl(new positon());

    // const bounds = maptest.getBounds();
    // console.log(bounds)

    // maptest.setMaxBounds(bounds);
    // maptest.fitBounds(bounds, { reset: true });



    UserService.getUserBoard().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, [maptest]);




  const init = async () => {

    sphere = window.sphere
    map = new sphere.Map({
      placeholder: document.getElementById('map'),
      // center: [100, 13],
      // zoom: 4
    });
    map._this = this;

    // map.Event.bind("ready", function () {
    //   map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
    //   map.Layers.setBase(sphere.Layers.HYBRID);
    //   map.Ui.DPad.visible(false);
    //   map.Ui.Geolocation.visible(true);
    //   map.Ui.Fullscreen.visible(false);
    //   map.Ui.LayerSelector.visible(false);
    //   layer_dri_province = new sphere.Layer('dri:province_with_c_dri', {
    //     type: sphere.LayerType.WMS,
    //     url: "https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/dri/wms?",
    //     zoomRange: { min: 1, max: 5.5 },
    //     zIndex: 5,
    //     opacity: 0.65,
    //     id: 'layer_dri_province',
    //     //extraQuery:'CQL_FILTER' + "=='ยโสธร'".replaceAll("=='ยโสธร'","=pv_tn='ยโสธร'")
    //   });
    //   //map.Layers.add(layer_dri_province);
    // });

    map.Event.bind(sphere.EventName.Click, function (location) {
      // console.log(location);
      // getDataLocation(location.lon, location.lat);
      //map.mouseLocation = location;
      var location = map.location(); // Cross hair location
      console.log(location);
    });

    const interval = setInterval(() => {
      // setDateTime(new Date());
      //reloadData();
      //goHome();
    }, 300000); //set your time here. repeat every 5 mins




    return () => {
      //window.removeEventListener('resize', handleWindowResize);
      clearInterval(interval);
    };




  }

  const init_analysis = async () => {

    if (!mapleaflet) return;

    console.log(mapleaflet)

    const legend = L.control({ position: "bottomleft" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "legend");
      div.innerHTML = `click on polygon`;
      return div;
    };

    legend.addTo(mapleaflet);

  }

  // const Legend = ({ maptest }) => {
  //   useEffect(() => {
  //     if (maptest) {
  //       const legend = L.control({ position: "bottomright" });

  //       legend.onAdd = () => {
  //         const div = L.DomUtil.create("div", "info legend");
  //         div.innerHTML =
  //           "<h4>This is the legend</h4>" +
  //           "<b>Lorem ipsum dolor sit amet consectetur adipiscing</b>";
  //         return div;
  //       };

  //       legend.addTo(maptest);
  //     }
  //   }, [maptest]);
  //   return null;
  // };

  const goHome = async () => {
    map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
  }

  const search_location = async () => {

    const response = await fetch(
      "https://api.sphere.gistda.or.th/services/search/search?keyword=กรุงเทพ&lat=13.7&lon=100.5&limit=3&showdistance=true&key=0BAF97C307C24B28A7E19CE1D9B1DE88"
    );
    const data = await response.json();
    console.log(data)

    var location = map.location(); // Cross hair location
    console.log(location);
    var result = map.bound();
    console.log(result);
  }



  const drawParcel = async () => {
    map.Event.bind(window.sphere.EventName.Ready, () => {
      //map.Ui.Toolbar.visible(false);
      map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
      map.Ui.DPad.visible(false);
      //map.zoom(14);
      map.Layers.setBase(sphere.Layers.HYBRID);
      map.Ui.LayerSelector.visible(false);
      //map.Ui.Fullscreen.visible(false);

      return (
        window.sphere.Util.loadStyle(sphere.Server.map + "/js/mapbox-gl-draw.css"),
        window.sphere.Util.loadScript(sphere.Server.map + "/js/mapbox-gl-draw.js", () => {

          const draw = new window.MapboxDraw({
            userProperties: true,
            displayControlsDefault: false,
            // Select which mapbox-gl-draw control buttons to add to the map.
            controls: {
              polygon: true,
              trash: true,
              point: false,
              line_string: false,
              combine_features: false,
              uncombine_features: false,
            },

            // Set mapbox-gl-draw to draw by default.
            // The user does not have to click the polygon control button first.
            defaultMode: 'draw_polygon'
          });
          //map.Renderer.addControl(draw);
          map.Renderer.addControl(draw, 'top-right'); // see details https://docs.mapbox.com/mapbox-gl-js/api/#map#addcontrol



          // map.on('load', function() {
          //     draw.add({ .. });
          //   });

          // function areaPolygon(data) {
          //   //console.log("areaPolygon",turf.area(data.features[0].geometry)/1600)
          //   return area(data.features[0].geometry) / 1600;
          // }

          map.Renderer.on('draw.create', function (e) {

            //console.log("draw.create",e)

            const data = draw.getAll();
            console.log(data)

            //el3.area_polygon.value = areaPolygon(data);
            //console.log(data)  



            geomInsert = JSON.stringify(data.features[0].geometry);
            //console.log(geomInsert)

            str = geomInsert.replace("Polygon", "MultiPolygon");
            str = str.replace(":[[[", ":[[[[");
            str = str.replace("]]]", "]]]]");
            console.log(str)

            setFetgeomInsert(str)



            area_polygon = area(data.features[0].geometry) / 1600;
            console.log(area_polygon)

            setFetarea_polygon(area_polygon)


            map.Renderer.on('draw.update', function (e) {
              //console.log("pm:edit",e)
              //data = e.target;
              const data = draw.getAll();
              console.log(data)

              //el3.area_polygon.value = areaPolygon(data);
              //console.log(data)  
              geomInsert = JSON.stringify(data.features[0].geometry);
              //console.log(geomInsert)

              str = geomInsert.replace("Polygon", "MultiPolygon");
              str = str.replace(":[[[", ":[[[[");
              str = str.replace("]]]", "]]]]");
              console.log(str)
              setFetgeomInsert(str)

              area_polygon = area(data.features[0].geometry) / 1600;
              console.log(area_polygon)
              setFetarea_polygon(area_polygon)
            });

          });
          //map.Renderer.on('draw.update', updateArea);

          //map.Renderer.on('draw.delete', updateArea);

          //drawing remove
          map.Renderer.on('draw.delete', function (e) {

            //el3.area_polygon.value = 0;

            // $("#area_per").val(0);

            geomInsert = null;

          });

        })

      )

    });


  }

  const refreshPage = async () => {
    init()
    // map.Event.bind(window.sphere.EventName.Ready, () => {
    //   map.Layers.setBase(sphere.Layers.HYBRID);

    //   //map.Ui.Toolbar.visible(false);
    //   map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
    //   // map.Ui.DPad.visible(false);
    //   // map.Ui.Scale.visible(true);
    //   // map.Ui.LayerSelector.visible(false);
    //   // map.Ui.Geolocation.visible(true);
    // });
    goHome()
    //init()
    location.reload();
  }

  const handleSubmit = async (event) => {
    setStatus(""); // Reset status
    event.preventDefault();
    //const formData = new FormData();
    //var geom2 = JSON.parse(fetgeomInsert)
    var geom2 = fetgeomInsert
    console.log(geom2)

    // const formData = {
    //   name_: name,
    //   username_: currentUser.username,
    //   geom: geom2,
    //   type_: type,
    //   pat: pat
    // }

    // let name_ = name
    // let username_ = currentUser.username
    // let geom = geom2
    // let type_ = type
    // let pat = 'web'


    // const formData = new FormData();
    // formData.append('file', files[0]);
    // formData.append('upload_preset', cloudinary_preset);
    // const res = await axios.post(
    //   `https://api.cloudinary.com/v1_1/${cloudinary_id}/image/upload`,
    //   formData,
    //   {
    //     headers: { 'Content-Type': 'multipart/form-data' }
    //   }
    // );
    const formData = new FormData();
    formData.append("name_", name);
    formData.append("username_", currentUser.username);
    formData.append("geom", geom2);
    formData.append("type_", type);
    formData.append("file", file);
    formData.append("pat", pat);

    console.log(file)



    //console.log(formData)
    if (area_polygon == 0) {

      Swal.fire(`<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">กรุณาวาดแปลงเกษตรกรรมเพื่อทําการวิเคราะห์</span> <span></span><br>`);
      //swal('กรุณาเลือกชนิดพืชที่ต้องการวิเคราะห์');

    } else if (area_polygon >= 10000) {

      Swal.fire(`<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">แปลงเกษตรกรรมมีขนาดมากว่า "10000 ไร่" กรุณาแก้ไข</span> <span></span><br>`)

    } else if (type === "") {

      Swal.fire(`<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">กรุณาเลือกชนิดพืช</span> <span></span><br>`)

    }
    else if (name === "") {

      Swal.fire(`<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">กรุณาใส่ชื่อแปลง</span> <span></span><br>`)

    } else if (geom2 == 0) {

      Swal.fire(`<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">กรุณาวาดแปลงเกษตรกรรมเพื่อทําการวิเคราะห์</span> <span></span><br>`)
    }
    else {

      //setStatus(resp.status === 200 ? "นําเข้าแปลงสําเร็จ!" : "Error.");
      Swal.fire({
        title: `<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">ยืนยันการนําเข้าแปลง?</span> <span></span><br>`,
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `<span style="font-weight: normal; font-family: 'kanit'; font-size: 16px">ยืนยัน!</span>`
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(url_draw, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then
            (response => {
              setResponse(response.data.success);
              //setInput({ name: '', email: '' });
              setFetgeomInsert(0);
              setName('');
              settype('');
              setpat('');
              setfile('');
              setFetarea_polygon(0);
              console.log(response.data.success)
              // Swal.fire({
              //   title: 'นําเข้าแปลงสําเร็จ',
              //   icon: 'success'
              //   //setStatus(resp.status === 200 ? "นําเข้าแปลงสําเร็จ!" : "Error."),   

              // })
              refreshPage()
            })
            .catch(error => {
              setErrors(error.response.data.errors);
            });


        }

      })
    }


  };


  const handleSubmit2 = async (event) => {
    map.Overlays.clear();
    setStatus(""); // Reset status
    event.preventDefault();
    //const formData = new FormData();
    //var geom2 = JSON.parse(fetgeomInsert)
    console.log(lat)
    // console.log(lng)

    let datalat_ = lat.split(" ");
    // console.log(datalat_[0].replace(',',''))
    // console.log(datalat_[1])

    if (lat == '') {
      Swal.fire(`<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">กรุณาใส่ข้อมูลพิกัด</span> <span></span><br>`);
    } else {
      var marker = new sphere.Marker({ lon: parseFloat(datalat_[1]), lat: parseFloat(datalat_[0].replace(',', '')) });
      console.log(marker)
      map.Overlays.add(marker);
      map.goTo({ center: { lon: parseFloat(datalat_[1]), lat: parseFloat(datalat_[0].replace(',', '')) }, zoom: 15 });
    }



    // Swal.fire('dsdsd')
    //console.log(formData)
    // if (lat == '') {

    //   Swal.fire(`<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">กรุณาใส่ข้อมูล</span> <span></span><br>`);
    //   //swal('กรุณาเลือกชนิดพืชที่ต้องการวิเคราะห์');

    // } else if (lng == '') {

    //   Swal.fire(`<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">กรุณาใส่ข้อมูล</span> <span></span><br>`)

    // } else {

    //   //setStatus(resp.status === 200 ? "นําเข้าแปลงสําเร็จ!" : "Error.");
    //   Swal.fire({
    //     title: `<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">ยืนยันการนําเข้าแปลง?</span> <span></span><br>`,
    //     text: "",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: `<span style="font-weight: normal; font-family: 'kanit'; font-size: 16px">ยืนยัน!</span>`
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       axios.post(url_draw, formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data'
    //         }
    //       }).then
    //         (response => {
    //           setResponse(response.data.success);
    //           //setInput({ name: '', email: '' });
    //           setFetgeomInsert(0);
    //           setName('');
    //           settype('');
    //           setpat('');
    //           setfile('');
    //           setFetarea_polygon(0);
    //           console.log(response.data.success)
    //           // Swal.fire({
    //           //   title: 'นําเข้าแปลงสําเร็จ',
    //           //   icon: 'success'
    //           //   //setStatus(resp.status === 200 ? "นําเข้าแปลงสําเร็จ!" : "Error."),   

    //           // })
    //           refreshPage()
    //         })
    //         .catch(error => {
    //           setErrors(error.response.data.errors);
    //         });


    //     }

    //   })
    //}


  };


  const tableParcel = async () => {
    //setStatus(""); // Reset status
    //event.preventDefault();
    //const formData = new FormData();


    const formData = {
      //username_: currentUser.username,
      id: currentUser.id
    }
    // formData.append("name", name); 

    axios.post(url_parcel_select, formData,).then

      (responseTable => {
        const result = responseTable.data.data.jsonFeatures
        console.log('result', result)
        var datatable = [];
        for (var i = 0; i < result.length; i++) {
          datatable.push({
            id_: result[i].id_,
            name_: result[i].name_,
            filepath: result[i].filepath,
            pat: result[i].pat,
            type_: result[i].type_,
            area_: result[i].area,
            des_: result[i].des_,
            geom: result[i].geojson,
            pv_tn: result[i].pv_tn,
            ap_tn: result[i].ap_tn,
            tb_tn: result[i].tb_tn,
            geom_get: {
              "type": "FeatureCollection",
              "features": [
                {
                  "type": "Feature",
                  "properties": {
                    id_: result[i].id_,
                    name_: result[i].name_,
                    pat: result[i].pat,
                    filepath: result[i].filepath,
                    pv_tn: result[i].pv_tn,
                    ap_tn: result[i].ap_tn,
                    tb_tn: result[i].tb_tn,
                    type_: result[i].type_,
                    area_: result[i].area,
                    des_: result[i].des_,
                    geom: result[i].geojson,
                  },
                  "geometry": result[i].geometry
                }
              ]
            }

          })
          //dataname_h.push(responseTable.data.data.jsonFeatures[i].time.split('T')[1].replace(':00+07:00', '') + ' น.');

        }

        console.log("users", datatable)

        setTableData(datatable)
        setIsLoading(false);


      })
      .catch(errorsTable => {
        //setErrorsTable(errorsTable.response.data.data.errorsTable);
      });
  };




  const getData = async (url, admin) => {
    const response = await fetch(url);
    const result = await response.json();
    console.log(result.data.jsonFeatures)

    // displayKey = 'pv_tn';
    // if (admin == 'ap') { displayKey = 'ap_tn' }
    // else if (admin == 'tb') { displayKey = 'tb_tn' }
    setFetchedData(result.data.jsonFeatures)

  };




  const analysis = async (obj) => {
    //setOpen(true);
    handleOpenBackdrop()
    handleOpen()

    // console.log(obj.geom_get)
    // console.log(obj)

    const geomdata = obj.geom
    //console.log(geomdata)
    const formData = {
      //geom: geomdata,
      id: obj.id_

    }

    axios.post(url_analysis_parcel, formData).then
      (responseTable => {
        const result = responseTable.data.data.jsonFeatures
        console.log('result', result)
        var mean_cdai = [];
        var mean_dri = [];
        var mean_ssm = [];
        var dri_f = [];
        var url_img = [];


        // for (var i = 0; i < result.length; i++) {
        //   datatable.push({
        //     name_: result[i].name_,
        //     pat: result[i].pat,
        //     type_: result[i].type_,
        //     area: result[i].area,
        //     des_: result[i].des_,
        //     geom: result[i].geojson
        //   })
        //   //dataname_h.push(responseTable.data.data.jsonFeatures[i].time.split('T')[1].replace(':00+07:00', '') + ' น.');

        // }
        for (var i = 0; i < result.length; i++) {
          mean_cdai.push(result[i].mean_cdai)
          mean_dri.push(result[i].mean_dri)
          mean_ssm.push(result[i].mean_ssm)
          url_img.push(result[i].url_img)
          dri_f.push(parseFloat(result[i].mean_dri_for_1))
          dri_f.push(parseFloat(result[i].mean_dri_for_2))
          dri_f.push(parseFloat(result[i].mean_dri_for_3))
          dri_f.push(parseFloat(result[i].mean_dri_for_4))
        }


        //console.log("mean_dri", mean_dri)
        //console.log("formData", formData)

        //setgeomdata_get(geomdata_get)
        setmean_cdai(mean_cdai)
        setmean_dri(mean_dri)
        setmean_ssm(mean_ssm)
        setdri_f(dri_f)
        seturl_img(url_img)
        setIsLoading(false);
        setOpenBackdrop(false);

        console.log(dri_f)

      })
      .catch(errorsTable => {
        //setErrorsTable(errorsTable.response.data.data.errorsTable);
      });

    const extent = bbox(obj.geom_get);
    console.log(extent)
    setgeomdata_get(obj.geom_get)
    setdata_get(obj)
    setgeomextent(extent)
    getChartData(obj)
    //OverlayImage(obj, maptest)

  }

  const del_parcel = async (obj) => {
    //const id = obj.id_
    //console.log(id)
    Swal.fire({
      title: `<span style="font-weight: normal; font-family: 'kanit'; font-size: 24px">ยืนยันการลบแปลง?</span> <span></span><br>`,
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `<span style="font-weight: normal; font-family: 'kanit'; font-size: 16px">ยืนยัน!</span>`
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(url_del_parcel, {
          id_: obj.id_
        }
        ).then
          (response => {
            console.log(response)
            //setResponse(response.data.success);
            //setInput({ name: '', email: '' });
            // setFetgeomInsert(0);
            // setName('');
            // settype('');
            // setpat('');
            // setFetarea_polygon(0);
            // Swal.fire({
            //   title: 'นําเข้าแปลงสําเร็จ',
            //   icon: 'success'
            //   //setStatus(resp.status === 200 ? "นําเข้าแปลงสําเร็จ!" : "Error."),   

            // })
            refreshPage()
          })
          .catch(error => {
            setErrors(error.response.data.errors);
          });
      }

    })
  }


  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleOpen = () => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 2000)
    return () => clearTimeout(timer);
  }

  const handleClose = () => {
    setOpen(false);
  };


  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };


  const OverlayImage = (obj, maptest) => {
    //const map = useMap();

    const extent = bbox(obj.geom_get);
    console.log(extent)

    var imageBounds = [
      [extent[1], extent[0]],
      [extent[3], extent[2]]
    ];

    //maptest.fitBounds(imageBounds);

    return (
      <ImageOverlay
        bounds={imageBounds}
        url={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAACCAYAAACQahZdAAAAHElEQVQImWNgwAWWMij+V2G48f+/ne1/BgYGBgA8ZQY620LX1gAAAABJRU5ErkJggg=='}
        transparent={true}
      />
    );
  }


  const options3 = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'การพยากรณ์เสี่ยงภัยแล้งล่วงหน้า 4 เดือน',
      align: 'center'
    },
    subtitle: {
      // text:
      //   'Source: <a target="_blank" ' +
      //   'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
      // align: 'left'
    },
    xAxis: {
      categories: ['กรกฏาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม'],
      crosshair: true,
      accessibility: {
        description: 'Countries'
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: 'เปอร์เซ็น'
      }
    },
    tooltip: {
      //valueSuffix: ' (1000 MT)'
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'ความเสี่ยงภัยแล้ง',
        data: dri_f,
        color: '#F18C0D',
      },
    ],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 300
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    }



  };

  Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: 'kanit',
      }
    }
  });


  // const optionsGeoraster = {
  //   // pixelValuesToColorFn: (values) => {
  //   //   // transforming single value into an rgba color
  //   //   // const nir = values[0];

  //   //   // if (nir === 0) return;
  //   //   // // console.log("nir:", nir);
  //   //   // const r = (nir / 20000) * 255;
  //   //   // const g = 0;
  //   //   // const b = 0;
  //   //   // return `rgba(${r},${g},${b}, 1)`;
  //   // },
  //   // resolution: 64,
  //   // opacity: 1
  // };
  //const Tiff = "https://cropsdrought-service.gistda.or.th/images/cdai_w45.tiff";

  //const Tiff  = "https://geotiff.github.io/georaster-layer-for-leaflet-example/example_4326.tif"


  return (
    <div>
      <Container fluid>


        {/* <header className="jumbotron">
          <h3>{content}</h3>
          ....
        </header> */}


        <Row className="justify-content-md-center" style={{ backgroundColor: 'rgb(243 245 255)' }}>

          <Col sm={8}>

            <Col xs={12} md={12} lg={12} xl={12} xxl={12} >
              <Box style={{ width: "100%", height: "80vh" }} id="map">
                {/* อาจเอาไว้ใส่พวกเครื่องหมายกลางจอ */}
                <Fab size='small' sx={{
                  width: '29px',
                  height: '25px',
                  minHeight: 'auto',
                  position: 'absolute',
                  top: 144,
                  left: 10,
                  borderRadius: 1,
                  color: '#21367C',
                  backgroundColor: '#FFF',
                  outline: '2px solid rgba(0, 0, 0, 0.1)',
                  boxShadow: 0,
                }}>
                  <HomeIcon
                    onClick={() => goHome()} />



                </Fab>

                {/* <Tooltip title="ขอบเขตแปลง">
                  <Fab size='small'
                    sx={{
                      width: '140px',
                      height: '40px',
                      minHeight: 'auto',
                      position: 'absolute',
                      //top: 144,
                      right: 10,
                      bottom: 100,
                      borderRadius: 1,
                      color: '#21367C',
                      backgroundColor: '#FFF',
                      outline: '2px solid rgba(0, 0, 0, 0.1)',
                      boxShadow: 0,
                    }}>
                    <FormControlLabel

                      control={
                        <Switch
                          edge="end"
                          onChange={handleToggle0('wifi2')}
                          checked={checked0}
                          inputProps={{
                            'aria-labelledby': 'switch-list-label-wifi2',
                          }}
                        />
                      }
                      label="ขอบเขตแปลง" />

                  </Fab>
                </Tooltip> */}

                {/* <Tooltip title="ขอบเขตแปลง">
                  <Fab size='small'
                    sx={{
                      width: '140px',
                      height: '40px',
                      minHeight: 'auto',
                      position: 'absolute',
                      //top: 144,
                      right: 10,
                      bottom: 100,
                      borderRadius: 1,
                      color: '#21367C',
                      backgroundColor: '#FFF',
                      outline: '2px solid rgba(0, 0, 0, 0.1)',
                      boxShadow: 0,
                    }}>
                    <FormControlLabel

                      control={
                        <Switch
                          edge="end"
                          onChange={handleToggle0('wifi2')}
                          checked={checked0}
                          inputProps={{
                            'aria-labelledby': 'switch-list-label-wifi2',
                          }}
                        />
                      }
                      label="ขอบเขตแปลง" />

                  </Fab>
                </Tooltip> */}

                <Tooltip style={{ fontFamily: 'Kanit' }} title="แผนที่ถนน">
                  <Fab size='small' sx={{
                    width: '140px',
                    height: '40px',
                    minHeight: 'auto',
                    position: 'absolute',
                    //top: 144,
                    right: 10,
                    bottom: 55,
                    borderRadius: 1,
                    color: '#21367C',
                    backgroundColor: '#FFF',
                    outline: '2px solid rgba(0, 0, 0, 0.1)',
                    boxShadow: 0,
                  }}>
                    <FormControlLabel
                      control={
                        <Switch
                          edge="end"
                          onChange={handleToggle0_1('wifi2')}
                          checked={checked0_1}
                          inputProps={{
                            'aria-labelledby': 'switch-list-label-wifi2',
                          }}
                        />
                      }
                      label="แผนที่ถนน" />
                  </Fab>
                </Tooltip>



                <Fab size='small' sx={{
                  width: '200px',
                  height: '0px',
                  minHeight: 'auto',
                  position: 'absolute',
                  //top: 144,
                  right: 10,
                  bottom: 180,
                  borderRadius: 1,
                  color: '#21367C',
                  backgroundColor: '#FFF',
                  outline: '2px solid rgba(0, 0, 0, 0.1)',
                  boxShadow: 0,
                }}>
                  <div class="card" style={{ backgroundColor: 'white', marginTop: 7, fontSize: 14.5 }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>พื้นที่วาด : หน่วยไร่</Form.Label>
                      <Form.Control type="email" placeholder="name@example.com" readOnly value={fetarea_polygon} disabled />
                    </Form.Group>
                  </div>

                </Fab>








                {/* <Tooltip title="คําอธิบายสัญลักษณ์">
                  <Fab size='small' sx={{
                    width: '120px',
                    height: '150px',
                    minHeight: 'auto',
                    position: 'absolute',
                    //top: 144,
                    right: 10,
                    bottom: 80,
                    borderRadius: 1,
                    color: '#21367C',
                    backgroundColor: '#FFF',
                    outline: '2px solid rgba(0, 0, 0, 0.1)',
                    boxShadow: 0,
                  }}>
                    <Image style={{ width: '100%' }} src="/legend.png" />
                  </Fab>
                </Tooltip> */}



              </Box>
            </Col>



          </Col>

          <Col sm={4} style={{ color: "", fontFamily: 'Kanit' }}>



            {/* <input
                type="text"
                id="message"
                name="message"
                onChange={handleChange}
                value={message} 
              />

              <h2>Message: {message}</h2> */}
            {/* <h2>Message: {area_polygon}</h2> */}
            {/* <ListGroup.Item>พื้นที่ {fetarea_polygon}ไร่</ListGroup.Item>
              <ListGroup.Item>{fetgeomInsert}</ListGroup.Item> */}
            {/* <h3>
                <strong>{currentUser.username}</strong>
              </h3> */}

            <br></br>

            {/* <div className="App">
              <div className="search-bar-container">
                <SearchBar setResults={setResults} />
                {results && results.length > 0 &&
                  <SearchResultsList results={results} />}
                <SearchResult setResults={setResults} />
              </div>

            </div> */}

            {/* <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}
            >
              <IconButton sx={{ p: '10px' }} aria-label="menu">
                <MenuIcon />
              </IconButton>
              <InputBase
                style={{ color: "", fontFamily: 'Kanit' }}
                sx={{ ml: 1, flex: 1 }}
                placeholder='ค้นหาสถานที่/พิกัดภูมิศาสตร์'
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: '10px' }}
                aria-label="directions"
                onClick={() => search_location()}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper> */}



            {/* <Form.Label>ค้นหาสถานที่/พิกัด</Form.Label>
            <Form.Control type="text" placeholder="" /> */}

            <div class="card" style={{ backgroundColor: 'white', marginTop: -16, marginBottom: 1, fontSize: 14.5 }}>
              ค้นหาตําแหน่งด้วยพิกัดภูมิศาสตร์
              <form >
                <label>
                  ละติดจูด(Lat), ลองจิจูด(Lng):
                  <input
                    class="form-control form-control-sm"
                    style={{ fontSize: 12.5 }}
                    type="text"
                    placeholder="13.000, 100.000"
                    value={lat}
                    onChange={e => {
                      console.log("lat", e.target.value);
                      setLat(e.target.value);
                    }}
                  />
                </label>
                {/* <label>
                  ลองจิจูด(Lng):
                  <input
                    class="form-control form-control-sm"
                    style={{ fontSize: 12.5 }}
                    type="text"
                    name="ลองจิจูด"
                    value={lng}
                    onChange={e => {
                      console.log("lng", e.target.value);
                      setLng(e.target.value);
                    }}
                  />
                </label> */}
                <button style={{ fontSize: 12.5 }} class="btn btn-info" type="submit" onClick={handleSubmit2}>ค้นหาพิกัด</button>
              </form>
            </div>




            {/* <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>ละติดจูด(Lat)</Form.Label>
                <Form.Control type="text" placeholder="13.0000" onChange={e => {
                  console.log("e.target.value", e.target.value);
                  setLat(e.target.value);
                }} value={lat} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>ลองจิจูด(Lng)</Form.Label>
                <Form.Control type="text" placeholder="100.0000" onChange={(e) => setLng(e.target.value)} value={lng} />
              </Form.Group>
              <Button variant="secondary" type="submit" onClick={() => { handleSubmit2 }}>
                ค้นหา
              </Button >

            </Form> */}
            <div class="card" style={{ backgroundColor: 'white', marginTop: 3, fontSize: 14.5 }}>
              <Form onSubmit={handleSubmit}>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>ชื่อแปลง</Form.Label>
                    <Form.Control size="sm" type="email" placeholder="" onChange={(e) => setName(e.target.value)} value={name} />
                    <Form.Text className="text-muted">
                      กรุณาใส่ชื่อแปลงเพาะปลูก
                    </Form.Text>
                  </Form.Group>


                  <Form.Group className="mb-3" controlId="formBasicSelect">
                    <Form.Label>ชนิดพืช</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={type}
                      onChange={e => {
                        console.log("e.target.value", e.target.value);
                        settype(e.target.value);
                      }}
                    >
                      <option value="">เลือกชนิดพืช</option>
                      <option value="ข้าว">ข้าว</option>
                      <option value="มันสําปะหลัง">มันสําปะหลัง</option>
                      <option value="อ้อยโรงงาน">อ้อยโรงงาน</option>
                      <option value="ข้าวโพดเลี้ยงสัตว์">ข้าวโพดเลี้ยงสัตว์</option>
                      <option value="อื่นๆ">อื่นๆ</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                      กรุณาใส่ชนิดพืช
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>อัพโหลดรูปถ่ายแปลง</Form.Label>
                    <Form.Control
                      size="sm"
                      type="file"
                      onChange={e => {
                        console.log("e.target.value", e.target.files[0]);
                        setfile(e.target.files[0]);
                      }}
                    />
                  </Form.Group>

                </Form>


                <input type="text" value={pat} hidden />
                <Button variant="success" type="submit" disabled={!(name) && !(type)}>
                  นําเข้าแปลง
                </Button >
                {/* {status ? <h1>{status}</h1> : null} */}
              </Form>
            </div>
            {/* <form onSubmit={handleSubmit}>
                <h1>React File Upload</h1>
                <input type="text" onChange={(e) => setName(e.target.value)} value={name} />
                <input type="text" onChange={(e) => settype(e.target.value)} value={type} />
                <input type="text" onChange={(e) => setpat(e.target.value)} value={pat} />
                <button type="submit" disabled={!(name)}>
                  Upload File
                </button>
                {status ? <h1>{status}</h1> : null}
              </form> */}
          </Col>

        </Row >




      </Container>

      {/* <TableContainer style={{ height: 625, maxHeight: 625 }} sx={{
        "&::-webkit-scrollbar": { width: 8, height: 8 },
        "&::-webkit-scrollbar-track": { backgroundColor: "#FFFAD7" },
        "&::-webkit-scrollbar-thumb": { backgroundColor: "#ffe670", borderRadius: 3 },
        "&::-webkit-scrollbar-thumb:hover": { background: "#fac02e" }
      }}>
        {
          fetchedData.map((rec, index) => (

            <TableRow style={{ borderBottom: "unset" }}>
              <TableCell component="th" scope="row" align="center" style={{ borderWidth: 0 }}>
                <div style={{ fontSize: 32, fontFamily: 'Kanit' }}> {index + 1} </div>
              </TableCell>
              <TableCell style={{ width: '100%' }} component="th" scope="row" align="left">
                <TableRow style={{ borderBottom: "unset", fontSize: 24, fontFamily: 'Kanit' }}>{rec[displayKey]}</TableRow>
                <TableRow style={{ borderBottom: "unset", color: '#8C8C8C', fontFamily: 'Kanit' }}>{rec.des_}</TableRow>
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                <div style={{
                  width: 64, height: 64, borderRadius: "50%", textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex',
                  background: rec.mean_ <= 50 ? '#ffea3b' : rec.mean_ <= 60 ? '#fac02e' : rec.mean_ <= 70 ? '#fe9701' : rec.mean_ <= 80 ? '#e57373' : '#f34236'
                }}>
                  <span style={{ color: "#FFF", fontSize: 24, fontFamily: 'Kanit' }}>{rec.mean_ == null ? '-' : rec.mean_}</span>
                </div>
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => drillDown(rec.pv_code, rec.ap_code, rec.tb_code, rec.geometry, rec.pv_tn, rec.ap_tn, rec.tb_tn)}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </TableCell>
            </TableRow>

          ))
        }
      </TableContainer> */}





      {isLoading
        ?
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        :
        <TableContainer component={Paper} >
          <Table sx={{ minWidth: 650 }} aria-label="simple table" >
            <TableHead>
              <TableRow >
                {/* <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>แผนที่แปลง</TableCell> */}
                <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>ชื่อแปลง</TableCell>
                <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>ที่ตั้ง</TableCell>
                <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>ชนิดพืช</TableCell>
                <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>พื้นที่(ไร่)</TableCell>
                <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>ระดับความเสี่ยงภัยแล้ง</TableCell>
                <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>รูปถ่ายแปลง</TableCell>
                <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>วิเคราะห์ข้อมูล</TableCell>
                <TableCell align="right" style={{ fontSize: 18, textAlign: "center", color: "", fontFamily: 'Kanit' }}>ลบแปลง</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {/* tableData.map((obj) => { */}
              {tableData.map((obj) => (
                <TableRow
                  key={obj.name_}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {/* <TableCell
                    style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} align="right">
                    {obj.geom_get.features[0].properties.area}
                    <MapContainer
                      whenCreated={setMap}
                      center={[obj.geom_get.features[0].geometry.coordinates[0][0][0][1], obj.geom_get.features[0].geometry.coordinates[0][0][0][0]]}
                      zoom={15}
                      scrollWheelZoom={false}
                      zoomControl={false}
                      style={{ height: "100px", width: "100px" }}
                    whenReady={(e) => {
                      e.target.flyToBounds([
                        [obj.geom_get.features[0].geometry.coordinates[0][0][0][1], obj.geom_get.features[0].geometry.coordinates[0][0][0][0]],
                        [obj.geom_get.features[0].geometry.coordinates[0][0][0][1], obj.geom_get.features[0].geometry.coordinates[0][0][0][0]],
                      ]);

                      console.log("This function will fire once the map is created",e)
                    }}
                    >
                      <TileLayer {...tileLayer_sphere} />

                      <GeoJSON data={obj.geom_get} onEachFeature={onEachFeature} style={{ color: '#ED2FED' }} />
                    </MapContainer>
                    {obj.geom_get.features[0].properties.area}
                  </TableCell> */}

                  <TableCell style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} component="th" scope="row">
                    {obj.name_}
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} align="right">{obj.pat}</TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} align="right">{obj.type_}</TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} align="right">{obj.area_}</TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} align="right">{obj.des_}</TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} align="right">
                    <Card.Img style={{ width: '100%', height: '100px' }} variant="top-t" src={`${obj.filepath}?w=164&h=164&fit=crop&auto=format`} />
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} align="right">
                    <Button
                      variant="outline-primary"
                      type="button"
                      id="button_id"
                      onClick={() => analysis(obj)}

                    >
                      รายละเอียดแปลง
                    </Button>

                    <Backdrop
                      sx={{
                        color: '#ffff',
                        zIndex: (theme) =>
                          Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
                      }}
                      //style={{zIndex: 1000}}
                      open={openBackdrop}
                      //onClick={handleCloseBackdrop}
                      closeAfterTransition

                    >
                      <CircularProgress
                        //color="inherit"
                        style={{ display: "inline-block", color: 'rgb(255, 230, 112)' }}
                        size={200}
                        label="freeSolo"
                        closeAfterTransition
                      //color={"#00db49"}

                      />

                    </Backdrop>

                    <Dialog
                      fullScreen
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Transition}

                    >
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                          <Typography style={{ fontSize: 24, textAlign: 'center', fontFamily: 'Kanit' }} sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            รายละเอียดแปลง
                          </Typography>
                          {/* <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                          </Button> */}
                        </Toolbar>
                      </AppBar>

                      <List>
                        <Container fluid>
                          {/* <Row className="show-grid"> */}
                          {/* <Col xs={12} md={2} lg={2}></Col> */}
                          {/* <Col xs={12} md={8} lg={8} > */}
                          {/* <div style={{ marginBottom: 21, padding: 14, background: '#ffe670', borderRadius: 7 }}> */}
                          <Card style={{ marginTop: 5, backgroundColor: '#ffffff' }}>
                            <div style={{ fontSize: 20, textAlign: 'left', fontFamily: 'Kanit' }}>
                              ชื่อแปลง: {data_get.name_}
                            </div>
                            <div style={{ fontSize: 20, textAlign: 'left', fontFamily: 'Kanit' }}>
                              ชนิดพืช: {data_get.type_}
                            </div>
                            <div style={{ fontSize: 20, textAlign: 'left', fontFamily: 'Kanit' }}>
                              ที่ตั้ง: จังหวัด{data_get.pv_tn}  อําเภอ{data_get.ap_tn}  ตําบล{data_get.tb_tn}
                            </div>
                            <div style={{ fontSize: 20, textAlign: 'left', fontFamily: 'Kanit' }}>
                              พิกัด: {[geomextent[1]]} , {[geomextent[0]]}
                            </div>
                            <div style={{ fontSize: 20, textAlign: 'left', fontFamily: 'Kanit' }}>
                              พื้นที่: {data_get.area_} ไร่
                            </div>
                          </Card>

                          {/* </div> */}
                          {/* </Col> */}
                          {/* <Col xs={12} md={2} lg={2}></Col>

                            <Col xs={12} md={2} lg={2}></Col> */}
                          {/* <Col xs={12} md={8} lg={8} style={{ marginBottom: 21, display: 'flex', fontFamily: 'Kanit' }}>
                              <div style={{ fontSize: 18, background: '#d5ff85', width: '25%', textAlign: 'center', borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}>0 - 50</div>
                              <div style={{ fontSize: 18, background: '#fef9d3', width: '25%', textAlign: 'center' }}>50.1 - 60</div>
                              <div style={{ fontSize: 18, background: '#f9bc42', width: '25%', textAlign: 'center' }}>60.1 - 70</div>
                              <div style={{ fontSize: 18, background: '#ff6600', width: '25%', textAlign: 'center' }}>70.1 - 80</div>
                              <div style={{ fontSize: 18, background: '#cc0000', width: '25%', textAlign: 'center', borderTopRightRadius: 7, borderBottomRightRadius: 7 }}>80.1 - 100</div>
                            </Col> */}
                          {/* </Row> */}
                        </Container>

                        {isLoading
                          ?
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                          :
                          <MapContainer

                            whenCreated={setMap}
                            center={[geomextent[1], geomextent[0]]}
                            zoom={21}
                            scrollWheelZoom={false}
                            zoomControl={false}
                            dragging={false}
                            doubleClickZoom={false}

                            // center={center}
                            // zoom={13}
                            // scrollWheelZoom={false}
                            // zoomControl={false}
                            // ref={mapRef}
                            style={{ height: "400px", width: "100%" }}
                            whenReady={(e) => {
                              e.target.flyToBounds([
                                [geomextent[1], geomextent[0]],
                                [geomextent[3], geomextent[2]]
                              ]);
                              //console.log("This function will fire once the map is created", e.target)
                            }}
                          >
                            {/* <ImageOverlay
                              bounds={[
                                [geomextent[1], geomextent[0]],
                                [geomextent[3], geomextent[2]]
                              ]}
                              url={url_img}
                              transparent={false}
                              opacity={1}
                            /> */}

                            {/* <GeoTiffLayer url={Tiff} options={optionsGeoraster} /> */}

                            {/* <TileLayer {...tileLayer_ESRI} /> */}
                            <TileLayer {...tileLayer_sphere} />

                            {/* Additional map layers or components can be added here */}
                            {mean_dri == null ?
                              <GeoJSON data={geomdata_get} style={style} /> : ''
                            }
                            {mean_dri != null && mean_dri <= 50 ?
                              <GeoJSON data={geomdata_get} style={{ color: '#d5ff85' }} /> : ''
                            }
                            {mean_dri >= 50.1 && mean_dri <= 60 ?
                              <GeoJSON data={geomdata_get} style={{ color: '#fef9d3' }} /> : ''
                            }
                            {mean_dri >= 60.1 && mean_dri <= 70 ?
                              <GeoJSON data={geomdata_get} style={{ color: '#f9bc42' }} /> : ''
                            }
                            {mean_dri >= 70.1 && mean_dri <= 80 ?
                              <GeoJSON data={geomdata_get} style={{ color: '#ff6600' }} /> : ''
                            }
                            {mean_dri >= 80.1 && mean_dri <= 100 ?
                              <GeoJSON data={geomdata_get} style={{ color: '#cc0000' }} /> : ''
                            }
                            {/* <WMSTileLayer
                            layers={"cadi:CDAI_w36_RICE_merged"}
                            url={`https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/cadi/wms?`}
                            maxZoom={30}
                          //params={layerParams}
                          /> */}

                            {/* <Tooltip title="คําอธิบายสัญลักษณ์">
                              <Fab size='small' sx={{
                                width: '120px',
                                height: '150px',
                                minHeight: 'auto',
                                position: 'absolute',
                                //top: 144,
                                right: 10,
                                bottom: 20,
                                borderRadius: 1,
                                color: '#21367C',
                                backgroundColor: '#FFF',
                                outline: '2px solid rgba(0, 0, 0, 0.1)',
                                boxShadow: 0,
                              }}>
                                <Image style={{ width: '100%' }} src="/legend/ssm.png" />
                              </Fab>
                            </Tooltip> */}

                            {/* <Tooltip title="คําอธิบายสัญลักษณ์">
                              <Fab size='small' sx={{
                                width: '120px',
                                height: '150px',
                                minHeight: 'auto',
                                position: 'absolute',
                                //top: 144,
                                right: 150,
                                bottom: 20,
                                borderRadius: 1,
                                color: '#21367C',
                                backgroundColor: '#FFF',
                                outline: '2px solid rgba(0, 0, 0, 0.1)',
                                boxShadow: 0,
                              }}>
                                <Image style={{ width: '100%' }} src="/legend/dri.png" />
                              </Fab>
                            </Tooltip> */}
                            

                        

                          </MapContainer>
                        }
                        <br />

                        {/* <ListItem>
                          <ListGroup.Item style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>ความเสี่ยงภัยแล้ง  {mean_dri} %</ListGroup.Item>
                          <ListItemText primary="ความเสี่ยงภัยแล้ง(%)" secondary="" />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListGroup.Item style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>ความเสียหายรายแปลง  {mean_cdai} %</ListGroup.Item>
                          <ListItemText
                            primary="Default notification ringtone"
                            secondary="Tethys"
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListGroup.Item style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>ความชื้นผิวดิน  0 ลบ ม.</ListGroup.Item>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListGroup.Item style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>ปริมาณฝนปัจจุบัน  0 มิลลิเมตร</ListGroup.Item>
                        </ListItem> */}
                        <Container fluid>
                          {isLoading
                            ?
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            :
                            <div>
                              <Card.Title style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>  ข้อมูลตรวจวัดจากระบบดาวเทียม </Card.Title>
                              <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'left', fontSize: 12 }}>
                                <Row className="justify-content-md-center" style={{ backgroundColor: 'rgb(243 245 255)' }}>
                                  <div class="container-fluid">
                                    <div class="row">
                                      <div class="col-6">
                                        <Alert style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }} icon={false} severity="warning">ดัชนีเสี่ยงภัยแล้ง {mean_dri} %</Alert>
                                        {parseInt(mean_dri) <= 50 ?
                                          <div class="container">
                                            <div class="row">
                                              <div class="col-6">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_1.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#D5FF85", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-12">
                                                    ไม่ได้รับความเสี่ยง
                                                  </div>
                                                </div>                                              </div>
                                              <div class="col-6">
                                                <br />
                                                <span style={{ textAlign: "left", fontSize: "16px", color: "", fontFamily: 'Kanit' }}>คําแนะนําสําหรับการเพาะปลูก สามารถเพาะปลูกพืชได้ โดยพิจารณาการใช้น้ำอย่างเหมาะสม</span>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                        {parseInt(mean_dri) >= 50.1 && parseInt(mean_dri) <= 60 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-6">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_2.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#FEF9D3", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-12">
                                                    โอกาสได้รับความเสี่ยงตํ่า
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-6">
                                                <br />
                                                <span style={{ textAlign: "left", fontSize: "16px", color: "", fontFamily: 'Kanit' }}>คําแนะนําสําหรับการเพาะปลูก สามารถเพาะปลูกพืชได้ โดยพิจารณาการใช้น้ำอย่างเหมาะสม</span>
                                              </div>
                                            </div>
                                          </div>

                                          :
                                          ''
                                        }
                                        {parseInt(mean_dri) >= 60.1 && parseInt(mean_dri) <= 70 ?
                                          <div class="container">
                                            <div class="row">
                                              <div class="col-6">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_3.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#F9BC42", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-12">
                                                    โอกาสได้รับความเสี่ยงปานกลาง
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-6">
                                                <span style={{ textAlign: "left", fontSize: "16px", color: "", fontFamily: 'Kanit' }}>คําแนะนําสําหรับการเพาะปลูก เกษตรกรควรคำนึงถึงปริมาณน้ำต้นทุนในการเพาะปลูก</span>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                        {parseInt(mean_dri) >= 70.1 && parseInt(mean_dri) <= 80 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-6">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_4.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#FF6600", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-12">
                                                    โอกาสได้รับความเสี่ยงสูง
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-6">
                                                <span style={{ textAlign: "left", fontSize: "16px", color: "", fontFamily: 'Kanit' }}>คําแนะนําสําหรับการเพาะปลูก ควรปลูกพืชใช้น้ำน้อย</span>
                                              </div>
                                            </div>
                                          </div>

                                          :
                                          ''
                                        }
                                        {parseInt(mean_dri) >= 80.1 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-6">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_5.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#CC0000", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-12">
                                                    โอกาสได้รับความเสี่ยงสูงมาก
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-6">
                                                <span style={{ textAlign: "left", fontSize: "16px", color: "", fontFamily: 'Kanit' }}>คําแนะนําสําหรับการเพาะปลูก งดการปลูกพืช</span>
                                              </div>
                                            </div>
                                          </div>

                                          :
                                          ''
                                        }

                                      </div>
                                      <div class="col-6">
                                        <Image style={{ width: 140, height: 170 }} src="/legend/dri.png" />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                                <br />

                                {/* <div>
                                  <span style={{ marginTop: 5, fontFamily: 'Kanit', backgroundColor: '#ffffff', textAlign: 'left', fontSize: 14, fontWeight:"normal"}}>
                                    ข้อมูล ณ (สัปดาห์ที่ {week})&nbsp;&nbsp;ช่วงวันที่&nbsp;
                                    {firstday} - {lastday}
                                  </span>
                                </div> */}

                              </Card>
                              <Divider />
                              <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'left', fontSize: 12 }}>
                                <Row className="justify-content-md-center" style={{ backgroundColor: 'rgb(243 245 255)' }}>
                                  <div class="container-fluid">
                                    <div class="row">
                                      <div class="col-6">
                                        <Alert style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }} icon={false} severity="warning">ดัชนีประเมินความเสียหายรายแปลง {mean_cdai} %</Alert>
                                        {parseInt(mean_cdai) <= 10 ?
                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_1.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#D5FF85", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    ไม่ได้รับความเสียหาย
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          :
                                          ''
                                        }
                                        {parseInt(mean_cdai) >= 10.1 && parseInt(mean_cdai) <= 20 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_2.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#FEF9D3", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    โอกาสได้รับความเสียหายตํ่า
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                        {parseInt(mean_cdai) >= 20.1 && parseInt(mean_cdai) <= 30 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_3.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#F9BC42", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    โอกาสได้รับความเสียหายปานกลาง
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                        {parseInt(mean_cdai) >= 30.1 && parseInt(mean_cdai) <= 40 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_4.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#FF6600", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    โอกาสได้รับความเสียหายสูง
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                        {parseInt(mean_cdai) >= 40.1 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/damage_5.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#CC0000", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    โอกาสได้รับความเสียหายสูงมาก
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                      </div>
                                      <div class="col-6">
                                        <Image style={{ width: 140, height: 170 }} src="/legend/cdai.png" />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </Card>
                              <Divider />

                              <Card style={{ marginTop: 5, backgroundColor: '#ffffff', textAlign: 'left', fontSize: 12 }}>
                                <Row className="justify-content-md-center" style={{ backgroundColor: 'rgb(243 245 255)' }}>
                                  <div class="container-fluid">
                                    <div class="row">
                                      <div class="col-6">
                                        <Alert style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }} icon={false} severity="warning">ความชื้นผิวดิน {mean_ssm} ลูกบาศก์เมตร</Alert>
                                        {parseFloat(mean_ssm) <= 0.10 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/soilmoisture_5.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#F44236", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    ความชื้นตํ่ามาก
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> :
                                          ''
                                        }
                                        {parseFloat(mean_ssm) >= 0.101 && parseFloat(mean_ssm) <= 0.20 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/soilmoisture_4.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#FF9702", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    ความชื้นตํ่า
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                        {parseFloat(mean_ssm) >= 0.201 && parseFloat(mean_ssm) <= 0.30 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/soilmoisture_3.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#FFF59C", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    ความชื้นปานกลาง
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                        {parseFloat(mean_ssm) >= 0.301 && parseFloat(mean_ssm) <= 0.40 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/soilmoisture_2.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#4CB050", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    ความชื้นสูง
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                        {parseFloat(mean_ssm) >= 0.401 ?

                                          <div class="container">
                                            <div class="row">
                                              <div class="col-12">
                                                <Image style={{ width: 100, height: 100 }} src="/legend/soilmoisture_1.png" />
                                                <div class="row">
                                                  <div style={{ textAlign: "left", backgroundColor: "#2196F3", fontSize: "16px", color: "", fontFamily: 'Kanit' }} class="col-6">
                                                    ความชื้นสูงมาก
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          ''
                                        }
                                      </div>
                                      <div class="col-6">
                                        <Image style={{ width: 140, height: 170 }} src="/legend/ssm.png" />
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </Card>
                              <Divider />
                              <div>
                                <Col style={{ marginTop: 5, fontFamily: 'Kanit', backgroundColor: '#ffffff', textAlign: 'left', fontSize: 14 }}>
                                  ข้อมูล ณ (สัปดาห์ที่ {week_})&nbsp;&nbsp;ช่วงวันที่&nbsp;
                                  {firstday} - {lastday}
                                </Col>
                              </div>
                            </div>


                          }
                        </Container>


                        <br />


                        <Container fluid>
                          <Card style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>
                            <HighchartsReact containerProps={{ style: { height: "500px" } }} highcharts={Highcharts} options={options3} />
                          </Card>
                        </Container>


                        <ListItem>

                          {isLoading
                            ?
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            :
                            <div class="card">
                              <Card.Title style={{ textAlign: "center", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>  ข้อมูลพยากรณ์อากาศล่วงหน้า 7 วัน (กรมอุตุนิยมวิทยา) </Card.Title>
                              {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}

                              <div class="d-flex justify-content text-center overflow-auto">
                                <div class="p-2 flex-fill ">
                                  <Card className="card-tmd" body>
                                    <Card.Title> {new Date(dataname[0]).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}</Card.Title>
                                    {/* <Card.Img variant="top" src= {`/img_weather_tmd/1Clear.svg`} /> */}
                                    {data_weatherTypeText[0] == 1 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/1Clear.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 2 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/2Partly-cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 3 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/3Cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 4 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/4Overcast.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 5 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/5Light-rain.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 6 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/6Moderate-rain.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 7 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/7Heavy-rain.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 8 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/8Thunderstorm.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 9 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/9Very-cold.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 10 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/10Cold.svg`} /> : ''}
                                    {data_weatherTypeText[0] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                    {data_weatherTypeText[0] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}

                                    <ListGroup className="list-group-flush">
                                      <ListGroup.Item>
                                        {data_weatherTypeText[0] == 1 ? 'ท้องฟ้าแจ่มใส (Clear)' : ''}
                                        {data_weatherTypeText[0] == 2 ? 'มีเมฆบางส่วน (Partly cloudy)' : ''}
                                        {data_weatherTypeText[0] == 3 ? 'เมฆเป็นส่วนมาก (Cloudy)' : ''}
                                        {data_weatherTypeText[0] == 4 ? 'มีเมฆมาก (Overcast)' : ''}
                                        {data_weatherTypeText[0] == 5 ? 'ฝนตกเล็กน้อย (Light rain)' : ''}
                                        {data_weatherTypeText[0] == 6 ? 'ฝนปานกลาง (Moderate rain)' : ''}
                                        {data_weatherTypeText[0] == 7 ? 'ฝนตกหนัก (Heavy rain)' : ''}
                                        {data_weatherTypeText[0] == 8 ? 'ฝนฟ้าคะนอง (Thunderstorm)' : ''}
                                        {data_weatherTypeText[0] == 9 ? 'อากาศหนาวจัด (Very cold)' : ''}
                                        {data_weatherTypeText[0] == 10 ? 'อากาศหนาว (Cold)' : ''}
                                        {data_weatherTypeText[0] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                        {data_weatherTypeText[0] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        อุณหภูมิ
                                        <div class="d-flex justify-content-around sub-heading">
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmax.svg`} />
                                          </div> {(data_temp_max)[0]}&deg;
                                          <div class="text-light2">|</div>
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmin.svg`} />
                                          </div>{data_temp_min[0]}&deg;
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item>ความชื้นสัมพัทธเฉลี่ยที่ระดับพื้นผิว {data_rh[0]} %</ListGroup.Item>
                                      <ListGroup.Item>ปริมาณฝนรวม 24 ชม. {data_rain[0]}  มิลลิเมตร</ListGroup.Item>
                                      <ListGroup.Item>ความเร็วลม {data_windSpeed[0]} เมตร/วินาที</ListGroup.Item>
                                    </ListGroup>
                                  </Card>
                                </div>
                                <div class="p-2 flex-fill ">
                                  <Card className="card-tmd" body>
                                    <Card.Title> {new Date(dataname[1]).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}</Card.Title>
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[1]}`} /> */}
                                    {data_weatherTypeText[1] == 1 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/1Clear.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 2 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/2Partly-cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 3 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/3Cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 4 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/4Overcast.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 5 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/5Light-rain.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 6 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/6Moderate-rain.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 7 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/7Heavy-rain.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 8 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/8Thunderstorm.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 9 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/9Very-cold.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 10 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/10Cold.svg`} /> : ''}
                                    {data_weatherTypeText[1] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                    {data_weatherTypeText[1] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}

                                    <ListGroup className="list-group-flush">
                                      <ListGroup.Item>
                                        {data_weatherTypeText[1] == 1 ? 'ท้องฟ้าแจ่มใส (Clear)' : ''}
                                        {data_weatherTypeText[1] == 2 ? 'มีเมฆบางส่วน (Partly cloudy)' : ''}
                                        {data_weatherTypeText[1] == 3 ? 'เมฆเป็นส่วนมาก (Cloudy)' : ''}
                                        {data_weatherTypeText[1] == 4 ? 'มีเมฆมาก (Overcast)' : ''}
                                        {data_weatherTypeText[1] == 5 ? 'ฝนตกเล็กน้อย (Light rain)' : ''}
                                        {data_weatherTypeText[1] == 6 ? 'ฝนปานกลาง (Moderate rain)' : ''}
                                        {data_weatherTypeText[1] == 7 ? 'ฝนตกหนัก (Heavy rain)' : ''}
                                        {data_weatherTypeText[1] == 8 ? 'ฝนฟ้าคะนอง (Thunderstorm)' : ''}
                                        {data_weatherTypeText[1] == 9 ? 'อากาศหนาวจัด (Very cold)' : ''}
                                        {data_weatherTypeText[1] == 10 ? 'อากาศหนาว (Cold)' : ''}
                                        {data_weatherTypeText[1] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                        {data_weatherTypeText[1] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        อุณหภูมิ
                                        <div class="d-flex justify-content-around sub-heading">
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmax.svg`} />
                                          </div> {(data_temp_max)[1]}&deg;
                                          <div class="text-light2">|</div>
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmin.svg`} />
                                          </div>{data_temp_min[1]}&deg;
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item>ความชื้นสัมพัทธเฉลี่ยที่ระดับพื้นผิว {data_rh[1]} %</ListGroup.Item>
                                      <ListGroup.Item>ปริมาณฝนรวม 24 ชม. {data_rain[1]}  มิลลิเมตร</ListGroup.Item>
                                      <ListGroup.Item>ความเร็วลม {data_windSpeed[1]} เมตร/วินาที</ListGroup.Item>
                                    </ListGroup>
                                  </Card>
                                </div>
                                <div class="p-2 flex-fill ">
                                  <Card className="card-tmd" body>
                                    <Card.Title> {new Date(dataname[2]).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}</Card.Title>
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[2]}`} /> */}
                                    {data_weatherTypeText[2] == 1 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/1Clear.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 2 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/2Partly-cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 3 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/3Cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 4 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/4Overcast.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 5 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/5Light-rain.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 6 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/6Moderate-rain.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 7 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/7Heavy-rain.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 8 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/8Thunderstorm.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 9 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/9Very-cold.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 10 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/10Cold.svg`} /> : ''}
                                    {data_weatherTypeText[2] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                    {data_weatherTypeText[2] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}

                                    <ListGroup className="list-group-flush">
                                      <ListGroup.Item>
                                        {data_weatherTypeText[2] == 1 ? 'ท้องฟ้าแจ่มใส (Clear)' : ''}
                                        {data_weatherTypeText[2] == 2 ? 'มีเมฆบางส่วน (Partly cloudy)' : ''}
                                        {data_weatherTypeText[2] == 3 ? 'เมฆเป็นส่วนมาก (Cloudy)' : ''}
                                        {data_weatherTypeText[2] == 4 ? 'มีเมฆมาก (Overcast)' : ''}
                                        {data_weatherTypeText[2] == 5 ? 'ฝนตกเล็กน้อย (Light rain)' : ''}
                                        {data_weatherTypeText[2] == 6 ? 'ฝนปานกลาง (Moderate rain)' : ''}
                                        {data_weatherTypeText[2] == 7 ? 'ฝนตกหนัก (Heavy rain)' : ''}
                                        {data_weatherTypeText[2] == 8 ? 'ฝนฟ้าคะนอง (Thunderstorm)' : ''}
                                        {data_weatherTypeText[2] == 9 ? 'อากาศหนาวจัด (Very cold)' : ''}
                                        {data_weatherTypeText[2] == 10 ? 'อากาศหนาว (Cold)' : ''}
                                        {data_weatherTypeText[2] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                        {data_weatherTypeText[2] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        อุณหภูมิ
                                        <div class="d-flex justify-content-around sub-heading">
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmax.svg`} />
                                          </div> {(data_temp_max)[2]}&deg;
                                          <div class="text-light2">|</div>
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmin.svg`} />
                                          </div>{data_temp_min[2]}&deg;
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item>ความชื้นสัมพัทธเฉลี่ยที่ระดับพื้นผิว {data_rh[2]} %</ListGroup.Item>
                                      <ListGroup.Item>ปริมาณฝนรวม 24 ชม. {data_rain[2]}  มิลลิเมตร</ListGroup.Item>
                                      <ListGroup.Item>ความเร็วลม {data_windSpeed[2]} เมตร/วินาที</ListGroup.Item>
                                    </ListGroup>
                                  </Card>
                                </div>
                                <div class="p-2 flex-fill ">
                                  <Card className="card-tmd" body>
                                    <Card.Title> {new Date(dataname[3]).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}</Card.Title>
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[3]}`} /> */}
                                    {data_weatherTypeText[3] == 1 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/1Clear.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 2 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/2Partly-cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 3 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/3Cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 4 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/4Overcast.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 5 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/5Light-rain.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 6 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/6Moderate-rain.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 7 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/7Heavy-rain.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 8 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/8Thunderstorm.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 9 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/9Very-cold.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 10 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/10Cold.svg`} /> : ''}
                                    {data_weatherTypeText[3] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                    {data_weatherTypeText[3] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                    <ListGroup className="list-group-flush">
                                      <ListGroup.Item>
                                        {data_weatherTypeText[3] == 1 ? 'ท้องฟ้าแจ่มใส (Clear)' : ''}
                                        {data_weatherTypeText[3] == 2 ? 'มีเมฆบางส่วน (Partly cloudy)' : ''}
                                        {data_weatherTypeText[3] == 3 ? 'เมฆเป็นส่วนมาก (Cloudy)' : ''}
                                        {data_weatherTypeText[3] == 4 ? 'มีเมฆมาก (Overcast)' : ''}
                                        {data_weatherTypeText[3] == 5 ? 'ฝนตกเล็กน้อย (Light rain)' : ''}
                                        {data_weatherTypeText[3] == 6 ? 'ฝนปานกลาง (Moderate rain)' : ''}
                                        {data_weatherTypeText[3] == 7 ? 'ฝนตกหนัก (Heavy rain)' : ''}
                                        {data_weatherTypeText[3] == 8 ? 'ฝนฟ้าคะนอง (Thunderstorm)' : ''}
                                        {data_weatherTypeText[3] == 9 ? 'อากาศหนาวจัด (Very cold)' : ''}
                                        {data_weatherTypeText[3] == 10 ? 'อากาศหนาว (Cold)' : ''}
                                        {data_weatherTypeText[3] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                        {data_weatherTypeText[3] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        อุณหภูมิ
                                        <div class="d-flex justify-content-around sub-heading">
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmax.svg`} />
                                          </div> {(data_temp_max)[3]}&deg;
                                          <div class="text-light2">|</div>
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmin.svg`} />
                                          </div>{data_temp_min[3]}&deg;
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item>ความชื้นสัมพัทธเฉลี่ยที่ระดับพื้นผิว {data_rh[3]} %</ListGroup.Item>
                                      <ListGroup.Item>ปริมาณฝนรวม 24 ชม. {data_rain[3]}  มิลลิเมตร</ListGroup.Item>
                                      <ListGroup.Item>ความเร็วลม {data_windSpeed[3]} เมตร/วินาที</ListGroup.Item>
                                    </ListGroup>
                                  </Card>
                                </div>
                                <div class="p-2 flex-fill ">
                                  <Card className="card-tmd" body>
                                    <Card.Title> {new Date(dataname[4]).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}</Card.Title>
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[4]}`} /> */}
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[3]}`} /> */}
                                    {data_weatherTypeText[4] == 1 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/1Clear.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 2 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/2Partly-cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 3 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/3Cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 4 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/4Overcast.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 5 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/5Light-rain.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 6 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/6Moderate-rain.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 7 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/7Heavy-rain.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 8 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/8Thunderstorm.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 9 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/9Very-cold.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 10 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/10Cold.svg`} /> : ''}
                                    {data_weatherTypeText[4] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                    {data_weatherTypeText[4] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                    <ListGroup className="list-group-flush">
                                      <ListGroup.Item>
                                        {data_weatherTypeText[4] == 1 ? 'ท้องฟ้าแจ่มใส (Clear)' : ''}
                                        {data_weatherTypeText[4] == 2 ? 'มีเมฆบางส่วน (Partly cloudy)' : ''}
                                        {data_weatherTypeText[4] == 3 ? 'เมฆเป็นส่วนมาก (Cloudy)' : ''}
                                        {data_weatherTypeText[4] == 4 ? 'มีเมฆมาก (Overcast)' : ''}
                                        {data_weatherTypeText[4] == 5 ? 'ฝนตกเล็กน้อย (Light rain)' : ''}
                                        {data_weatherTypeText[4] == 6 ? 'ฝนปานกลาง (Moderate rain)' : ''}
                                        {data_weatherTypeText[4] == 7 ? 'ฝนตกหนัก (Heavy rain)' : ''}
                                        {data_weatherTypeText[4] == 8 ? 'ฝนฟ้าคะนอง (Thunderstorm)' : ''}
                                        {data_weatherTypeText[4] == 9 ? 'อากาศหนาวจัด (Very cold)' : ''}
                                        {data_weatherTypeText[4] == 10 ? 'อากาศหนาว (Cold)' : ''}
                                        {data_weatherTypeText[4] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                        {data_weatherTypeText[4] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        อุณหภูมิ
                                        <div class="d-flex justify-content-around sub-heading">
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmax.svg`} />
                                          </div> {(data_temp_max)[4]}&deg;
                                          <div class="text-light2">|</div>
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmin.svg`} />
                                          </div>{data_temp_min[4]}&deg;
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item>ความชื้นสัมพัทธเฉลี่ยที่ระดับพื้นผิว {data_rh[4]} %</ListGroup.Item>
                                      <ListGroup.Item>ปริมาณฝนรวม 24 ชม. {data_rain[4]}  มิลลิเมตร</ListGroup.Item>
                                      <ListGroup.Item>ความเร็วลม {data_windSpeed[4]} เมตร/วินาที</ListGroup.Item>
                                    </ListGroup>
                                  </Card>
                                </div>
                                <div class="p-2 flex-fill ">
                                  <Card className="card-tmd" body>
                                    <Card.Title> {new Date(dataname[5]).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}</Card.Title>
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[5]}`} /> */}
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[3]}`} /> */}
                                    {data_weatherTypeText[5] == 1 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/1Clear.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 2 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/2Partly-cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 3 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/3Cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 4 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/4Overcast.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 5 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/5Light-rain.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 6 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/6Moderate-rain.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 7 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/7Heavy-rain.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 8 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/8Thunderstorm.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 9 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/9Very-cold.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 10 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/10Cold.svg`} /> : ''}
                                    {data_weatherTypeText[5] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                    {data_weatherTypeText[5] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                    <ListGroup className="list-group-flush">
                                      <ListGroup.Item>
                                        {data_weatherTypeText[5] == 1 ? 'ท้องฟ้าแจ่มใส (Clear)' : ''}
                                        {data_weatherTypeText[5] == 2 ? 'มีเมฆบางส่วน (Partly cloudy)' : ''}
                                        {data_weatherTypeText[5] == 3 ? 'เมฆเป็นส่วนมาก (Cloudy)' : ''}
                                        {data_weatherTypeText[5] == 4 ? 'มีเมฆมาก (Overcast)' : ''}
                                        {data_weatherTypeText[5] == 5 ? 'ฝนตกเล็กน้อย (Light rain)' : ''}
                                        {data_weatherTypeText[5] == 6 ? 'ฝนปานกลาง (Moderate rain)' : ''}
                                        {data_weatherTypeText[5] == 7 ? 'ฝนตกหนัก (Heavy rain)' : ''}
                                        {data_weatherTypeText[5] == 8 ? 'ฝนฟ้าคะนอง (Thunderstorm)' : ''}
                                        {data_weatherTypeText[5] == 9 ? 'อากาศหนาวจัด (Very cold)' : ''}
                                        {data_weatherTypeText[5] == 10 ? 'อากาศหนาว (Cold)' : ''}
                                        {data_weatherTypeText[5] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                        {data_weatherTypeText[5] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        อุณหภูมิ
                                        <div class="d-flex justify-content-around sub-heading">
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmax.svg`} />
                                          </div> {(data_temp_max)[5]}&deg;
                                          <div class="text-light2">|</div>
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmin.svg`} />
                                          </div>{data_temp_min[5]}&deg;
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item>ความชื้นสัมพัทธเฉลี่ยที่ระดับพื้นผิว {data_rh[5]} %</ListGroup.Item>
                                      <ListGroup.Item>ปริมาณฝนรวม 24 ชม. {data_rain[5]}  มิลลิเมตร</ListGroup.Item>
                                      <ListGroup.Item>ความเร็วลม {data_windSpeed[5]} เมตร/วินาที</ListGroup.Item>
                                    </ListGroup>
                                  </Card>
                                </div>
                                <div class="p-2 flex-fill ">
                                  <Card className="card-tmd" body>
                                    <Card.Title> {new Date(dataname[6]).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}</Card.Title>
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[6]}`} /> */}
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[5]}`} /> */}
                                    {/* <Card.Img variant="top" src={`https://service-proxy-765rkyfg3q-as.a.run.app/api_tmd/CustomTheme3${data_weatherTypeImagePath[3]}`} /> */}
                                    {data_weatherTypeText[6] == 1 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/1Clear.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 2 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/2Partly-cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 3 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/3Cloudy.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 4 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/4Overcast.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 5 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/5Light-rain.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 6 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/6Moderate-rain.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 7 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/7Heavy-rain.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 8 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/8Thunderstorm.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 9 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/9Very-cold.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 10 ?
                                      <Card.Img variant="top" src={`/img_weather_tmd/10Cold.svg`} /> : ''}
                                    {data_weatherTypeText[6] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                    {data_weatherTypeText[6] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                    <ListGroup className="list-group-flush">
                                      <ListGroup.Item>
                                        {data_weatherTypeText[6] == 1 ? 'ท้องฟ้าแจ่มใส (Clear)' : ''}
                                        {data_weatherTypeText[6] == 2 ? 'มีเมฆบางส่วน (Partly cloudy)' : ''}
                                        {data_weatherTypeText[6] == 3 ? 'เมฆเป็นส่วนมาก (Cloudy)' : ''}
                                        {data_weatherTypeText[6] == 4 ? 'มีเมฆมาก (Overcast)' : ''}
                                        {data_weatherTypeText[6] == 5 ? 'ฝนตกเล็กน้อย (Light rain)' : ''}
                                        {data_weatherTypeText[6] == 6 ? 'ฝนปานกลาง (Moderate rain)' : ''}
                                        {data_weatherTypeText[6] == 7 ? 'ฝนตกหนัก (Heavy rain)' : ''}
                                        {data_weatherTypeText[6] == 8 ? 'ฝนฟ้าคะนอง (Thunderstorm)' : ''}
                                        {data_weatherTypeText[6] == 9 ? 'อากาศหนาวจัด (Very cold)' : ''}
                                        {data_weatherTypeText[6] == 10 ? 'อากาศหนาว (Cold)' : ''}
                                        {data_weatherTypeText[6] == 11 ? 'อากาศเย็น (Cool)' : ''}
                                        {data_weatherTypeText[6] == 11 ? 'อากาศร้อนจัด (Very hot)' : ''}
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        อุณหภูมิ
                                        <div class="d-flex justify-content-around sub-heading">
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmax.svg`} />
                                          </div> {(data_temp_max)[6]}&deg;
                                          <div class="text-light2">|</div>
                                          <div>
                                            <Card.Img variant="top-t" src={`/img_weather_tmd/tmin.svg`} />
                                          </div>{data_temp_min[6]}&deg;
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item>ความชื้นสัมพัทธเฉลี่ยที่ระดับพื้นผิว {data_rh[6]} %</ListGroup.Item>
                                      <ListGroup.Item>ปริมาณฝนรวม 24 ชม. {data_rain[6]}  มิลลิเมตร</ListGroup.Item>
                                      <ListGroup.Item>ความเร็วลม {data_windSpeed[6]} เมตร/วินาที</ListGroup.Item>
                                    </ListGroup>
                                  </Card>
                                </div>
                              </div>

                            </div>
                          }

                        </ListItem>
                      </List>
                    </Dialog>
                  </TableCell>


                  <TableCell style={{ fontSize: 16, textAlign: "center", color: "", fontFamily: 'Kanit' }} align="right">
                    <Button
                      variant="outline-danger"
                      type="button"
                      id="button_id2"
                      onClick={() => del_parcel(obj)}
                    >
                      ลบแปลง
                    </Button>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }


    </div >
  );
};

export default BoardUser;
