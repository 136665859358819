import React, { useEffect } from "react";
import AuthService from "../services/auth.service";
import ReactGA from "react-ga4";
import axios from 'axios';

const Profile = () => {
  const currentUser = AuthService.getCurrentUser();

  console.log(currentUser)

  let data = JSON.stringify({
    "id": 103
  });

  //  axios.post("https://drought-765rkyfg3q-as.a.run.app/api/auth/getuserdatabyid", data, {
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   }
  // }).then
  //   (response => {

  //     console.log(response)
  //     // setResponse(response.data.success);
  //     // //setInput({ name: '', email: '' });
  //     // setFetgeomInsert(0);
  //     // setName('');
  //     // settype('');
  //     // setpat('');
  //     // setFetarea_polygon(0);
  //     // // Swal.fire({
  //     // //   title: 'นําเข้าแปลงสําเร็จ',
  //     // //   icon: 'success'
  //     // //   //setStatus(resp.status === 200 ? "นําเข้าแปลงสําเร็จ!" : "Error."),   

  //     // // })
  //     // refreshPage()
  //   })
  //   .catch(error => {
  //     setErrors(error.response.data.errors);
  //   });



  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-access-token", currentUser.accessToken);

  const raw = JSON.stringify({
    "id": 103
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  fetch("https://drought-765rkyfg3q-as.a.run.app/api/auth/getuserdatabyid_auth", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));



  //console.log(currentUser)
  useEffect(() => {
    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/profile", title: "profile" });

  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3 style={{ textAlign: "center", margin: "10px", fontSize: "36px", color: "", fontFamily: 'Kanit' }}>
          ยินดีต้อนรับ<strong>{currentUser.username}</strong>
        </h3>
      </header>
      {/* <p>
        <strong>Token:</strong> {currentUser.accessToken}
      </p> */}
      {/* <p>
        <strong>Id:</strong> {currentUser.id}
      </p> */}
      <p>
        <strong style={{ textAlign: "center", fontWeight: "normal", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>ชื่อผู้ใช้งาน (Username): {currentUser.username}</strong>
      </p>
      <p>
        <strong style={{ textAlign: "center", fontWeight: "normal", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>อีเมล(Email): {currentUser.email}</strong>
      </p>
      <p>
        <strong style={{ textAlign: "center", fontWeight: "normal", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>เบอร์โทร (Phone No): {currentUser.tel}</strong>
      </p>
      {/* <strong>Authorities:</strong>
      <ul>
        {currentUser.roles &&
          currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      </ul> */}
    </div>
  );
};

export default Profile;
